var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',[_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":_vm.testType,"collapse":false,"router":true}},[_c('el-menu-item',{attrs:{"index":"weekly","route":{
          query: {
            test_type: 'weekly'
          }
        }}},[_c('i',{staticClass:"el-icon-menu"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("班級完整考試")])]),_c('el-menu-item',{attrs:{"index":"material","route":{
          query: {
            test_type: 'material'
          }
        }}},[_c('i',{staticClass:"el-icon-folder-opened"}),_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("上課文件中的考試")])])],1)],1),_c('div',{staticClass:"pl-4",staticStyle:{"flex":"1","overflow":"hidden"}},[(_vm.testType === 'weekly')?_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h4","content":"班級完整考試"}},[(_vm.roleIsTeacherOrAdmin)?_c('router-link',{attrs:{"slot":"button","to":{
          name: 'sessionClassesEdit',
          params: { id: _vm.$route.params.classId },
          query: { edit_test: 1 }
        }},slot:"button"},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fa fa-edit"})])],1):_vm._e()],1):_vm._e(),(_vm.testType === 'material')?_c('Heading',{staticClass:"mb-4",attrs:{"heading":"h4","content":"上課文件中的練習"}},[(_vm.roleIsTeacherOrAdmin)?_c('router-link',{attrs:{"slot":"button","to":{
          name: 'sessionClassesEdit',
          params: { id: _vm.$route.params.classId },
          query: { section: 'materials' }
        }},slot:"button"},[_c('el-button',{attrs:{"type":"primary","size":"mini"}},[_c('i',{staticClass:"fa fa-edit"})])],1):_vm._e()],1):_vm._e(),_c('hr'),_c('StudentTestScoreTable',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.section),expression:"!section"}],attrs:{"element-loading-spinner":"el-icon-loading","section":_vm.section,"type":"tests","disableEdit":!_vm.roleIsTeacherOrAdmin,"displayStudentUserIds":_vm.displayStudentUserIds},on:{"getSessions":_vm.getSessions}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }